import { Component, Vue } from 'vue-property-decorator';
import Swal from 'sweetalert2';

@Component({
  name: 'NotificationMixin'
})
export default class NotificationMixin extends Vue {
  readonly $swal!: any;
  notifyInfo (title) {
    this.$swal({
      icon: 'info',
      title,
      // timer: 3000,
      showConfirmButton: false,
      toast: true,
      position: 'top'
    });
  }

  notifySuccess (title) {
    this.$swal({
      icon: 'success',
      title,
      timer: 3000,
      showConfirmButton: false,
      toast: true,
      position: 'top'
    });
  }
  
  notifyError (title) {
    this.$swal({
      icon: 'error',
      title,
      timer: 10000,
      showConfirmButton: false,
      toast: true,
      position: 'top'
    });
  }
}
